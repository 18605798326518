/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Nav, Spinner, Tab } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { formatToBR } from "../../../../core/helpers/date";
import http from "../../../../core/services/http";
import SubscriptionStatus from "../../../../_metronic/layout/components/subscriptionStatusLabel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function NewArrivals({ className }) {
  const [filterPediod, setFilterPeriod] = useState("Semana");
  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(true);

  async function fetchPartners(params = {}) {
    setLoadingPartners(true)
    return await http.get('partner/search', params)
      .then(({ data: responseData }) => setPartners(responseData.data))
      .catch(console.log)
      .finally(() => setLoadingPartners(false))
  }

  useEffect(() => {
    let params = {
      withSubscription: true
    }

    switch (filterPediod) {
      default:
        params.createdInMonth = true
        break
      case 'Semana':
        params.createdThisWeek = true
        break
      case 'Dia':
        params.createdToday = true
        break
    }

    fetchPartners({ params })
  }, [filterPediod])

  return (
    <div className="card card-custom h-100">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Pré cadastros
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {partners.length} novos contadores cadastrados
          </span>
        </h3>
        <div className="card-toolbar">
          <Tab.Container defaultActiveKey={filterPediod}>
            <Nav
              as="ul"
              onSelect={_key => setFilterPeriod(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Mês"
                  className={`nav-link py-2 px-4 ${filterPediod === "Mês" ? "active" : ""
                    }`}
                >
                  Mês
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Semana"
                  className={`nav-link py-2 px-4 ${filterPediod === "Semana" ? "active" : ""
                    }`}
                >
                  Semana
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Dia"
                  className={`nav-link py-2 px-4 ${filterPediod === "Dia" ? "active" : ""
                    }`}
                >
                  Dia
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive mb-5 h-100 overflow-auto" style={{ maxHeight: '375px' }}>
          <table className="table table-borderless table-vertical-center mb-0 pb-0">
            <thead>
              <tr>
                <th className="p-0" style={{ width: "50px" }} />
                <th className="p-0" style={{ minWidth: "200px" }} />
                <th className="p-0" style={{ minWidth: "70px" }} />
                <th className="p-0" style={{ minWidth: "50px" }} />
              </tr>
            </thead>
            <tbody>
              {loadingPartners === true
                ?
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  </td>
                </tr>
                :
                partners.length ? partners.map(partner => (
                  <tr key={partner.id}>
                    <td className="pl-0 py-4">
                      <div className="symbol symbol-50 symbol-light mr-1">
                        {partner.avatar ?
                          <img
                            src={partner.avatarSrc}
                            alt="avatar"
                            className="symbol-label h-50 align-self-center"
                          />
                          :
                          <span className="symbol-label">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                              className="h-50 align-self-center"
                            ></SVG>
                          </span>
                        }
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        style={{ width: '150px', display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        {partner.name} teste de teste da silva salro aff
                    </a>
                      <div>
                        <span className="font-weight-bolder">cadastrado:</span>{" "}
                        <span className="text-muted font-weight-bold" >
                          {' -- '}
                        </span>
                      </div>
                    </td>
                    <td className="text-right">
                      <span className="text-muted font-weight-500">
                        {formatToBR(partner.createdAt)}
                      </span>
                      <div></div>
                    </td>
                    <td className="text-right">
                      <SubscriptionStatus subscription={partner.subscription} />
                    </td>
                    <td className="text-right pr-4">
                      <NavLink to={`/accountants/${partner.id}`} className="btn btn-icon btn-light btn-sm">
                        <span className="mx-2 svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Detalhes"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          ></SVG>
                        </span>
                      </NavLink>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td className="pl-0 py-4 text-center" colSpan={5}>
                      <span className="text-muted font-weight-500">
                        Nenhum registro encontrado
                    </span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
