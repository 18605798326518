import React, { useState } from "react";
import { Spinner, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import http from "../../../core/services/http";

export const CreateAccountantForm = ({ onCreated }) => {
  const { register, handleSubmit, errors } = useForm()
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [requestError, setRequestError] = useState({ show: false })

  const submitAccountant = (data) => {
    setLoadingCreate(true)
    http.post('partner', data)
      .then(() => onCreated())
      .catch(({ response }) => setRequestError({
        show: true, ...response.data
      }))
      .finally(() => setLoadingCreate(false))
  }

  const fieldErrors = (errors) => {
    let item = []
    for (let error in errors) {
      item.push(errors[error][0])
    }
    return item
  }

  return (
    <>
      {requestError.show &&
        <Alert variant="danger" dismissible onClose={() => setRequestError({ show: false })}>
          {requestError.message}
          {fieldErrors(requestError.errors).map((error, key) => (
            <li key={key}>{error}</li>
          ))}
        </Alert>
      }

      <form onSubmit={handleSubmit(submitAccountant)} className="">
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="name">Nome</label>
            <input
              name="name"
              ref={register({ required: true })}
              id="name"
              type="text"
              className="form-control"
            />
            {errors.name && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              ref={register({ required: true })}
              id="email"
              type="text"
              className="form-control"
            />
            {errors.email && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="cnpj">CNPJ</label>
            <input
              name="cnpj"
              ref={register({ required: true })}
              id="cnpj"
              type="text"
              className="form-control"
            />
            {errors.cnpj && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="phone">Telefone Comercial</label>
            <input
              name="phone"
              ref={register({ required: true })}
              id="phone"
              type="text"
              className="form-control"
            />
            {errors.phone && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="whatsapp">Whatsapp</label>
            <input
              name="whatsapp"
              ref={register({ required: true })}
              id="whatsapp"
              type="text"
              className="form-control"
            />
            {errors.whatsapp && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="customerQuantity">Carteira de clientes (qtd)</label>
            <input
              name="customerQuantity"
              ref={register({ required: true })}
              id="customerQuantity"
              type="number"
              className="form-control"
            />
            {errors.customerQuantity && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="password">Senha</label>
            <input
              name="password"
              ref={register({ require: true })}
              id="password"
              type="text"
              className="form-control"
            />
            {errors.password && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="password_confirmation">Confirma senha</label>
            <input
              name="password_confirmation"
              ref={register({ required: true })}
              id="password_confirmation"
              type="text"
              className="form-control"
            />
            {errors.password_confirmation && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>


        <div className="form-group row">
          <div className="col-md-12">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loadingCreate}
            >
              Salvar
              {loadingCreate && <Spinner animation="border" size="sm" className="ml-2" />}
            </button>
          </div>
        </div>

      </form>
    </>
  )
}
