import React from "react";

export default function SubscriptionStatus({ subscription }) {
  let color, status;
  switch (subscription?.status) {
    case 'confirmed':
      color = 'label-light-success'
      status = 'Confirmado'
      break;
    case 'pending':
      color = 'label-light-warning'
      status = 'Processando'
      break;
    default:
      color = 'label-light-primary'
      status = 'Trial'
      break;
  }

  return (
    <span className={`label label-lg ${color} label-inline`}>
      {status}
    </span>
  )
}
