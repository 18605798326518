import React, { useEffect, useState } from "react";
import http from '../../../core/services/http'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Modal, Spinner, Alert } from "react-bootstrap";
import {CreateInstitutionForm} from "./create";
import {CreateProductsForm} from "./createProductsForm";

export const Index = () => {

  const [accountants, setAccountants] = useState([]);
  const [loadingAccountants, setLoadingAccountants] = useState(true);
  const [createAccountantModal, SetCreateAccountantModal] = useState(false);
  const [attachProductsModal, setAttachProductsModal] = useState(false);
  const [accountantCreated, setAccountantCreated] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [productTypeList, setProductTypeList] = useState({ show: false });

  function fetchAccountants() {
    http.get('financial-institution')
      .then((res) => {
        setAccountants(res.data.data);
      })
      .catch(console.log)
      .finally(() => setLoadingAccountants(false))
  }

  function fetchProductsType() {
    http.get('product-type')
      .then((res) => {
        setProductTypeList(res.data.data);
      })
      .catch(console.log);
  }

  useEffect(() => {
    fetchAccountants();
    fetchProductsType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccountantCreated = () => {
    SetCreateAccountantModal(false)
    fetchAccountants()
    setAccountantCreated(true)
    setTimeout(() => {
      setAccountantCreated(false)
    }, 4000);
  }

  const handleProductsCreated = () => {
    setAttachProductsModal(false)
  }

  const TableComponent = () => {
    const headerSortingStyle = { backgroundColor: '#ebedf3' }

    const columns = [
      {
        dataField: 'avatar',
        text: 'Foto',
        formatter: (cell) => {
          return (
            <div className="flex justify-content-center items-center">
              <img src={process.env.REACT_APP_AWS_S3_URL + cell} className="rounded d-block" style={{ height: "120px", width: "120px" }} alt={cell}/>
            </div>
          )
        }
      },
      {
        dataField: 'name',
        text: 'Nome',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'description',
        text: 'Descrição',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'products',
        text: 'Produtos',
        formatter: (cell, row) => cell.length
      },
      {
        dataField: 'id',
        text: '',
        formatter: (cell, row) => (
          <div className="d-block text-right space-x-2">
            <button onClick={() => {
              setIdSelected(row.id);
              setAttachProductsModal(true)
            }} className="btn btn-icon btn-light btn-sm">
              <span className="mx-2 svg-icon svg-icon-md svg-icon-info">
                <SVG
                  title="Settings"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/Settings-1.svg"
                  )}
                />
              </span>
            </button>
          </div>
        )
      }
    ]

    const { SearchBar } = Search;
    return (
      <ToolkitProvider
        keyField='id'
        data={accountants}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div className="d-flex justify-content-between mb-4">
                <SearchBar placeholder="Pesquisar" {...props.searchProps} />
              </div>

              <BootstrapTable
                pagination={paginationFactory({ hideSizePerPage: true })}
                bordered={false}
                classes="table table-head-custom table-vertical-center table-head-bg table-borderless"
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    )
  }

  const CreateAccountantModal = () => (
    <Modal onHide={() => SetCreateAccountantModal(false)} show={createAccountantModal} centered >
      <Modal.Header closeButton>
        <Modal.Title>Cadastro de parceiros</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateInstitutionForm onCreated={handleAccountantCreated}/>
      </Modal.Body>
    </Modal>
  )

  const AttachProductsModal = () => (
    <Modal onHide={() => setAttachProductsModal(false)} show={attachProductsModal} centered >
      <Modal.Header closeButton>
        <Modal.Title>Cadastro de produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateProductsForm idInstitution={idSelected} onCreated={handleProductsCreated} productTypeList={productTypeList} />
      </Modal.Body>
    </Modal>
  )


  return (
    <>
      <div className="row justify-content-center align-items-md-center">
        <div className="col-md-12">
          {accountantCreated && <Alert variant="success" dismissible className="mb-8" >
            cadastrado com sucesso
          </Alert>}
          <div className="card full-height bg-ligth">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Instituições</h3>

              <button
                className="btn btn-light btn-sm d-flex align-items-center"
                onClick={() => SetCreateAccountantModal(true)}
                title="Cadastrar"
              >
                <span className="text-primary">Cadastrar</span>
                <span title="Cadastrar" className="ml-2 svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    title="Cadastrar"
                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                  />
                </span>
              </button>
            </div>
            <div className="card-body">
              {loadingAccountants === true
                ?
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
                :
                <TableComponent />}
            </div>
          </div>
        </div>
      </div>
      <AttachProductsModal />
      <CreateAccountantModal />
    </>
  );
};
