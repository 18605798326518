import React, { useState } from "react";
import { Spinner, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import http from "../../../core/services/http";

export const CreateProductTypeForm = ({ onCreated, idInstitution }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [requestError, setRequestError] = useState({ show: false })
  const [inputFieldsList, setInputFieldsList] = useState([ { name: "", label: "", type: "string" } ]);

  const submitAccountant = (data) => {
    let newData = data;
    newData.fields = inputFieldsList.filter(elem => elem.name.length !== 0);
    setLoadingCreate(true)
    http.post('/product-type', newData)
      .then(() => onCreated())
      .catch(({ response }) => setRequestError({
        show: true, ...response.data
      }))
      .finally(() => setLoadingCreate(false))
  }

  const deleteField = (index) => {
    let newFields = inputFieldsList.map(elem => elem);
    newFields.splice(index, 1);
    setInputFieldsList(newFields);
  }

  const fieldErrors = (errors) => {
    let item = []
    for (let error in errors) {
      item.push(errors[error][0])
    }
    return item
  }

  const convertName = (string) => {
    let converted = string.toLowerCase();
    converted = converted.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    converted = converted.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    converted = converted.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    converted = converted.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    converted = converted.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    converted = converted.replace(new RegExp('[Ç]','gi'), 'c');
    converted = converted.replace(new RegExp( ' ', 'gs'), "_");
    return converted;
  }

  const changeInputField = (index, field, value) => {
    let newInputField = inputFieldsList.map(elem => elem);
    newInputField[index][field] = value;
    if(field === 'label') newInputField[index].name = convertName(value);
    setInputFieldsList(newInputField);
  }

  return (
    <>
      {requestError.show &&
      <Alert variant="danger" dismissible onClose={() => setRequestError({ show: false })}>
        {requestError.message}
        {fieldErrors(requestError.errors).map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </Alert>
      }

      <form onSubmit={handleSubmit(submitAccountant)} className="">
        <input type="hidden" id="financial_institution_id" name="financial_institution_id" value={idInstitution}/>
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="name">Nome</label>
            <input
              name="name"
              ref={register({ required: true })}
              id="name"
              type="text"
              className="form-control"
            />
            {errors.name && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row mb-2">
          <div className="col-md-12">
            <label>Campos Dinamicos:</label>
          </div>
        </div>

        {inputFieldsList.map((elem, index) => (
          <div className="form-group row">
            <div className="col-md-5">
              <input type="text" placeholder="Nome do campo"
                     onChange={(event) => changeInputField(index, 'label', event.target.value)}
                     value={inputFieldsList[index].label} className="form-control mr-1"/>
              {errors["input" + index] && <span className="text-danger">Este campo é obrigatório</span>}
            </div>
            <div className="col-md-5">
              <select onChange={(event) => changeInputField(index, 'type', event.target.value)}
                      value={inputFieldsList[index].type}
                      className="form-control ml-1">
                <option value="string">Texto</option>
                <option value="number">Número</option>
              </select>
            </div>
            <div className="col-md-2">
              {index !== 0 ? (
                <button
                  className="btn btn-light btn-sm d-flex align-items-center"
                  onClick={() => deleteField(index)}
                  type="button"
                  title="Deletar"
                >
                  <span className="text-danger">Deletar</span>
                </button>
              ) : ""}
            </div>
          </div>
        ))}

        <div className="form-group row">
          <div className="col-md-12">
            <button
              className="btn btn-secondary float-right"
              type="button"
              onClick={() => setInputFieldsList([ ...inputFieldsList, { name: "", label: "", type: "string" } ])}
            >
              Adicionar Campo
            </button>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loadingCreate}
            >
              Salvar
              {loadingCreate && <Spinner animation="border" size="sm" className="ml-2" />}
            </button>
          </div>
        </div>

      </form>
    </>
  )
}
