import React, { useEffect, useState } from "react";
import http from '../../../core/services/http'
import { formatToBR } from "../../../core/helpers/date";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CreateAccountantForm } from './create'
import { NavLink } from "react-router-dom";
import SubscriptionStatus from "../../../_metronic/layout/components/subscriptionStatusLabel";
import { Tab, Nav, Modal, Spinner, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import { maskCnpj } from "../../../core/helpers/mask";
// import Swal from 'sweetalert2'

export const Accountants = () => {

  const [accountants, setAccountants] = useState([])
  const [loadingAccountants, setLoadingAccountants] = useState(true)
  const [createAccountantModal, SetCreateAccountantModal] = useState(false)
  const [accountantCreated, setAccountantCreated] = useState(false)
  const [filterStatus, setFilterStatus] = useState('all')

  function fetchAccountants() {
    let params = {};

    if (filterStatus !== 'all') {
      params.status = filterStatus
    }

    http.get('partner/search?withTrashed=true&withSubscription=true&withSale=true', { params })
      .then(({ data: resData }) => setAccountants(resData.data))
      .catch(console.log)
      .finally(() => setLoadingAccountants(false))
  }

  useEffect(() => {
    fetchAccountants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus])

  // function restorePartner(partnerId) {
  //   Swal.fire({
  //     title: 'Tem certeza?',
  //     icon: 'info',
  //     text: 'Esta ação irá restaurar o cadastro!',
  //     showCloseButton: true,
  //     showCancelButton: true,
  //     focusConfirm: true,
  //     confirmButtonText: 'Ok!',
  //     cancelButtonText: 'Cancelar',
  //   }).then(({ isConfirmed }) => {
  //     if (!isConfirmed) {
  //       return
  //     }
  //
  //     setLoadingAccountants(true)
  //     http.put(`partner/restore/${partnerId}`)
  //       .then(({ data: resData }) => fetchAccountants())
  //       .catch(() => {
  //         Swal.fire({
  //           title: 'Erro ao restaurar!',
  //           icon: 'error',
  //         })
  //       })
  //       .finally(() => setLoadingAccountants(false))
  //   })
  // }

  // function deletePartner(partnerId) {
  //   Swal.fire({
  //     title: 'Tem certeza?',
  //     icon: 'info',
  //     text: 'Esta ação irá bloquear o cadastro!',
  //     showCloseButton: true,
  //     showCancelButton: true,
  //     focusConfirm: true,
  //     confirmButtonText: 'Ok!',
  //     cancelButtonText: 'Cancelar',
  //   }).then(({ isConfirmed }) => {
  //     if (!isConfirmed) {
  //       return
  //     }
  //
  //     setLoadingAccountants(true)
  //     http.delete(`partner/${partnerId}`)
  //       .then(({ data: resData }) => fetchAccountants())
  //       .catch(() => {
  //         Swal.fire({
  //           title: 'Erro ao bloquear!',
  //           icon: 'error',
  //         })
  //       })
  //       .finally(() => setLoadingAccountants(false))
  //   })
  // }

  const handleAccountantCreated = () => {
    SetCreateAccountantModal(false)
    fetchAccountants()
    setAccountantCreated(true)
    setTimeout(() => {
      setAccountantCreated(false)
    }, 4000);
  }

  const TableComponent = () => {
    const headerSortingStyle = { backgroundColor: '#ebedf3' }

    const columns = [
      {
        dataField: 'name',
        text: 'Nome',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'cnpj',
        text: 'Cnpj',
        sort: true,
        formatter: (cell, row) => (maskCnpj(row.cnpj)),
        headerSortingStyle
      },
      {
        dataField: 'createdAt',
        text: 'Cadastrado',
        sort: true,
        formatter: (cell, row) => (<span>{formatToBR(row.createdAt)}</span>),
        headerSortingStyle
      },
      {
        dataField: 'subscription',
        text: 'Status',
        sort: true,
        formatter: (cell, row) => (
          <>
            {row.deletedAt
              ?
              <span className={`label label-lg label-light-default label-inline`}>
                Desativado
              </span>
              :
              <SubscriptionStatus subscription={row.subscription} />
            }

            {!row.sale?.meetingHeldManagerId &&
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-${row.id}`}>
                    <strong>Agendar reunião</strong>.
                  </Tooltip>
                }
              >
                <i className="flaticon2-information icon-md text-muted ml-2" />
              </OverlayTrigger>
            }
          </>
        ),
        headerSortingStyle
      },
      {
        dataField: 'id',
        text: '',
        formatter: (cell, row) => (
          <div className="d-block text-right space-x-2">
            {/*<button onClick={() => {}} className="btn btn-icon btn-light btn-sm">*/}
            {/*  <span className="mx-2 svg-icon svg-icon-md svg-icon-info">*/}
            {/*    <SVG*/}
            {/*      title="Settings"*/}
            {/*      src={toAbsoluteUrl(*/}
            {/*        "/media/svg/icons/General/Settings-1.svg"*/}
            {/*      )}*/}
            {/*    ></SVG>*/}
            {/*  </span>*/}
            {/*</button>*/}

            <NavLink to={`/accountants/${row.id}`} className="btn btn-icon btn-light btn-sm">
              <span className="mx-2 svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  title="Detalhes"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Code/Right-circle.svg"
                  )}
                />
              </span>
            </NavLink>

            {/*{row.deletedAt ?*/}
            {/*  <button onClick={() => restorePartner(row.id)} className="btn btn-icon btn-light btn-sm">*/}
            {/*    <span className="mx-2 svg-icon svg-icon-md svg-icon-danger">*/}
            {/*      <SVG*/}
            {/*        title="Desbloquear"*/}
            {/*        src={toAbsoluteUrl(*/}
            {/*          "/media/svg/icons/General/Unlock.svg"*/}
            {/*        )}*/}
            {/*      ></SVG>*/}
            {/*    </span>*/}
            {/*  </button>*/}
            {/*  :*/}
            {/*  <button onClick={() => deletePartner(row.id)} className="btn btn-icon btn-light btn-sm">*/}
            {/*    <span className="mx-2 svg-icon svg-icon-md svg-icon-danger">*/}
            {/*      <SVG*/}
            {/*        title="Desbloquear"*/}
            {/*        src={toAbsoluteUrl(*/}
            {/*          "/media/svg/icons/General/Lock.svg"*/}
            {/*        )}*/}
            {/*      ></SVG>*/}
            {/*    </span>*/}
            {/*  </button>*/}
            {/*}*/}
          </div>
        )
      }
    ]

    const { SearchBar } = Search;
    return (
      <ToolkitProvider
        keyField='id'
        data={accountants}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div className="d-flex justify-content-between mb-4">
                <SearchBar placeholder="Pesquisar" {...props.searchProps} />

                <Tab.Container defaultActiveKey={filterStatus}>
                  <Nav
                    as="ul"
                    onSelect={_key => setFilterStatus(_key)}
                    className="nav nav-pills nav-pills-sm nav-dark-75"
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="trial"
                        className={`nav-link py-2 px-4 ${filterStatus === "trial" ? "active" : ""
                          }`}
                      >
                        Trial
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="withTrashed"
                        className={`nav-link py-2 px-4 ${filterStatus === "withTrashed" ? "active" : ""
                          }`}
                      >
                        Cancelado
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="confirmed"
                        className={`nav-link py-2 px-4 ${filterStatus === "confirmed" ? "active" : ""
                          }`}
                      >
                        Confirmado
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="all"
                        className={`nav-link py-2 px-4 ${filterStatus === "all" ? "active" : ""
                          }`}
                      >
                        Todos
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
              </div>

              <BootstrapTable
                pagination={paginationFactory({ hideSizePerPage: true })}
                bordered={false}
                classes="table table-head-custom table-vertical-center table-head-bg table-borderless"
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    )
  }

  const CreateAccountantModal = () => (
    <Modal onHide={() => SetCreateAccountantModal(false)} show={createAccountantModal} centered >
      <Modal.Header closeButton>
        <Modal.Title>Cadastro de parceiros</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateAccountantForm onCreated={handleAccountantCreated} />
      </Modal.Body>
    </Modal>
  )

  return (
    <>
      <div className="row justify-content-center align-items-md-center">
        <div className="col-md-12">
          {accountantCreated && <Alert variant="success" dismissible className="mb-8" >
            cadastrado com sucesso
          </Alert>}
          <div className="card full-height bg-ligth">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Contadores</h3>

              <button
                className="btn btn-light btn-sm d-flex align-items-center"
                onClick={() => SetCreateAccountantModal(true)}
                title="Cadastrar"
              >
                <span className="text-primary">Cadastrar</span>
                <span title="Cadastrar" className="ml-2 svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    title="Cadastrar"
                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                  />
                </span>
              </button>
            </div>
            <div className="card-body">
              {loadingAccountants === true
                ?
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
                :
                <TableComponent />}
            </div>
          </div>
        </div>
      </div>
      <CreateAccountantModal />
    </>
  );
};
