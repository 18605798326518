import Axios from "axios";
import store from "../../redux/store";
import { createBrowserHistory } from 'history';

const {
  REACT_APP_API_URL: API_URL
} = process.env;

const http = Axios.create({
  baseURL: API_URL
})

http.interceptors.request.use(
  config => {
    const { auth: { authToken } } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  config => config,
  error => {
    if (error.response.status === 401) {
        createBrowserHistory().push('/logout')
        window.location.reload()
    }
    return Promise.reject(error)
  })

export default http
