import {format} from 'date-fns'
import {ptBR} from 'date-fns/locale'

export function formatToBR(date) {
  if (!date) {
    return '--'
  }
  if (date.length > 10) {
    date = date.substr(0, 10)
  }

  let [y, m, d] = date.split('-')
  return format(
    new Date(y, m - 1, d),
    'PP', {
      locale: ptBR
    }
  )
}
