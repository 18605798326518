import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import http from "../../../core/services/http";
import { formatToBR } from "../../../core/helpers/date";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Modal, Spinner } from "react-bootstrap";
import { SaleForm } from "./sale-form";
import { Alert } from "react-bootstrap";

export function Sales() {

  const [sales, setSales] = useState([])
  const [loadingSales, setLoadingSales] = useState(true)
  const [changedDealModal, setChangedDealModal] = useState(false)
  const [tablePage, setTablePage] = useState(1);

  const [dealModal, setDealModal] = useState({
    show: false,
    loading: false,
    data: {}
  })

  const handleDealModalClose = () => setDealModal({
    data: {},
    show: false,
    loading: false
  })

  const handleDealModalShow = async (row) => {
    setDealModal({
      ...dealModal,
      show: true,
      loading: true,
    })

    const { data: customer } = await fetchCustomerById(row.customer_id)

    setDealModal({
      data: {
        ...row, customer
      },
      show: true,
      loading: false,
    })

  }

  async function fetchCustomerById(id) {
    return await http.get(`partner/customer/${id}`)
      .then(({ data }) => data)
      .catch(({response}) => response.data)
  }

  function fetchDealOpportunities() {
    http.get('partner/deal-opportunities')
      .then(({ data }) => setSales(data))
      .catch(console.log)
      .finally(() => setLoadingSales(false))
  }

  useEffect(() => {
    fetchDealOpportunities()
  }, [])

  const handleChanged = () => {
    setDealModal(false)
    fetchDealOpportunities()
    setChangedDealModal(true)
    setTimeout(() => {
      setChangedDealModal(false)
    }, 4000);
  }

  const TableComponent = () => {
    const headerSortingStyle = { backgroundColor: '#ebedf3' }

    const formatStatus = (cell, row) => {
      let color, text;
      switch (row.status) {
        case 'canceled':
          color = 'label-light-warning'
          text = 'Cancelado'
          break;
        case 'pending':
          color = 'label-light-secondary text-dark'
          text = 'Pendente'
          break;
        case 'processing':
          color = 'label-light-primary'
          text = 'Processando'
          break;
        case 'concluded':
          color = 'label-light-success'
          text = 'Concluído'
          break;
        case 'rejected':
          color = 'label-light-danger'
          text = 'Indeferido'
          break;
        default:
          color = 'label-light-secondary text-dark'
          text = '--'
          break;
      }
      return (<span className={`label label-lg label-inline ${color}`}>{text}</span>)
    }

    const actionCell = (cell, row) => {
      return (
        <div className="text-right">
          <button
            className="btn btn-icon btn-light btn-sm"
            onClick={() => handleDealModalShow(row)}
            title="Detalhes"
          >
            <span title="Detalhes" className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Detalhes"
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
              />
            </span>
          </button>
          {/* <a href="#" className="btn btn-icon btn-light btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Write.svg"
                )}
              ></SVG>
            </span>
          </a> */}
        </div>
      )
    }

    const columns = [
      {
        dataField: 'customer.name',
        text: 'Cliente',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'partner.name',
        text: 'Contador',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'product_category',
        text: 'Produto',
        sort: true,
        headerSortingStyle
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerSortingStyle,
        formatter: formatStatus,
      },
      {
        dataField: 'created_at',
        text: 'Cadastrado',
        sort: true,
        headerSortingStyle,
        formatter: (cell, row) => (<span>{formatToBR(row.created_at)}</span>)
      },
      {
        dataField: 'id',
        text: '',
        formatter: actionCell
      }
    ]

    return (
      <BootstrapTable
        classes="table table-head-custom table-vertical-center table-head-bg table-borderless"
        bordered={false}
        keyField='id'
        data={sales}
        columns={columns}
        pagination={paginationFactory(
          {
            hideSizePerPage: true,
            page: tablePage,
            onPageChange: (page) => setTablePage(page)
          })}
      />
    )
  }

  const DealModalComponent = () => (
    <Modal show={dealModal.show} onHide={handleDealModalClose} centered >
      {dealModal.loading
        ?
        <div className="d-flex justify-content-center py-10">
          <Spinner animation="border" />
        </div>
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>Solicitação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SaleForm data={dealModal.data} onChanged={handleChanged} />
          </Modal.Body>
        </>
      }
    </Modal>
  )

  return (
    <>
      <div className="row justify-content-center align-items-md-center">
        <div className="col-md-12">
          {changedDealModal && <Alert variant="success" dismissible className="mb-8" >
            Venda atualizada com sucesso!
          </Alert>}
          <div className="card full-height bg-ligth card-custom">
            <div className="card-header">
              <h3 className="card-title">Vendas</h3>
            </div>
            <div className="card-body">
              {loadingSales === true
                ?
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
                :
                <TableComponent />}
            </div>
          </div>
        </div>
      </div>
      <DealModalComponent />
    </>
  );
}
