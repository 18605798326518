import http from '../../../../core/services/http';

export const LOGIN_URL = "/auth/login";
export const REGISTER_URL = "/auth/register";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";

export const ME_URL = "/manager/by-token";

export function login(email, password) {
    return http.post(LOGIN_URL, {email, password});
}

export function register(email, fullname, username, password) {
    return http.post(REGISTER_URL, {email, fullname, username, password});
}

export function requestPassword(email) {
    return http.post(REQUEST_PASSWORD_URL, {email});
}

export function getUserByToken() {
    return http.get(ME_URL);
}
