import React, { useEffect, useState } from "react";
import http from '../../../core/services/http'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { CreateProductTypeForm } from "./createProductTypeForm";

export const ProductTypeIndex = () => {

  const [productType, setProductType] = useState([])
  const [loadingAccountants, setLoadingAccountants] = useState(true)
  const [createProductTypeModal, SetCreateProductTypeModal] = useState(false)
  const [accountantCreated, setAccountantCreated] = useState(false)

  function fetchAccountants() {

    http.get('product-type')
      .then((res) => {
        setProductType(res.data.data);
      })
      .catch(console.log)
      .finally(() => setLoadingAccountants(false))
  }

  useEffect(() => {
    fetchAccountants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccountantCreated = () => {
    SetCreateProductTypeModal(false)
    fetchAccountants()
    setAccountantCreated(true)
    setTimeout(() => {
      setAccountantCreated(false)
    }, 4000);
  }

  const TableComponent = () => {
    const headerSortingStyle = { backgroundColor: '#ebedf3' }

    const columns = [
      {
        dataField: 'name',
        text: 'Nome',
        sort: true,
        headerSortingStyle
      }
    ]

    const { SearchBar } = Search;
    return (
      <ToolkitProvider
        keyField='id'
        data={productType}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div className="d-flex justify-content-between mb-4">
                <SearchBar placeholder="Pesquisar" {...props.searchProps} />
              </div>

              <BootstrapTable
                pagination={paginationFactory({ hideSizePerPage: true })}
                bordered={false}
                classes="table table-head-custom table-vertical-center table-head-bg table-borderless"
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    )
  }

  const CreateProductTypeModal = () => (
    <Modal onHide={() => SetCreateProductTypeModal(false)} show={createProductTypeModal} centered >
      <Modal.Header closeButton>
        <Modal.Title>Cadastro de tipos de produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateProductTypeForm onCreated={handleAccountantCreated} />
      </Modal.Body>
    </Modal>
  )

  return (
    <>
      <div className="row justify-content-center align-items-md-center">
        <div className="col-md-12">
          {accountantCreated && <Alert variant="success" dismissible className="mb-8" >
            cadastrado com sucesso
          </Alert>}
          <div className="card full-height bg-ligth">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Tipos de Produtos</h3>

              <button
                className="btn btn-light btn-sm d-flex align-items-center"
                onClick={() => SetCreateProductTypeModal(true)}
                title="Cadastrar"
              >
                <span className="text-primary">Cadastrar</span>
                <span title="Cadastrar" className="ml-2 svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    title="Cadastrar"
                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                  />
                </span>
              </button>
            </div>
            <div className="card-body">
              {loadingAccountants === true
                ?
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
                :
                <TableComponent />}
            </div>
          </div>
        </div>
      </div>
      <CreateProductTypeModal />
    </>
  );
};
