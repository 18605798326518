import React, { useState, useEffect } from "react";
import { Spinner, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { formatToBR } from "../../../core/helpers/date";
import { maskCnpj } from "../../../core/helpers/mask";
import http from "../../../core/services/http";
import SubscriptionStatus from "../../../_metronic/layout/components/subscriptionStatusLabel";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OnboardingResume } from "./onboarding";

export const EditAccountant = (props) => {
  const [loadingPartner, setLoadingPartner] = useState(true)
  const [loadingCustomer, setLoadingCustomer] = useState(true)
  // const [loadingOnboarding, setLoadingOnboarding] = useState(true)
  const [partner, setPartner] = useState(null)
  const [customers, setCustomers] = useState([])
  const [onboarding, setOnboarding] = useState('profile')
  const [tab, setTab] = useState('profile')

  // const [copied, setCopied] = useState(false)
  //
  // function updateCopied() {
  //   setCopied(true)
  //   setTimeout(() => {
  //     setCopied(false)
  //   }, 3000);
  // }

  function fetchPartner() {
    http.get(`partner/${props.match.params.id}`)
      .then(({ data: responseData }) => setPartner(responseData.data))
      .catch(console.log)
      .finally(() => setLoadingPartner(false))
  }

  function fetchCustomers() {
    http.get(`partner/customer?partner_id=${props.match.params.id}`)
      .then(({ data: responseData }) => setCustomers(responseData.data))
      .catch(console.log)
      .finally(() => setLoadingCustomer(false))
  }

  function fetchOnboarding() {
    http.get(`partner/${props.match.params.id}/onboarding`)
      .then(({ data: responseData }) => setOnboarding(responseData.data))
      .catch(console.log)
      // .finally(() => setLoadingOnboarding(false))
  }

  useEffect(() => {
    fetchPartner()
    fetchCustomers()
    fetchOnboarding()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const customerColumns = [
    {
      dataField: 'name',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'id',
      text: '',
      formatter: (row, cell) => {
        return (
          <>
            {!row.agree &&
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-${row.id}`}>
                    <strong>Sem consentimento</strong>.
                  </Tooltip>
                }
              >
                <i className="flaticon2-information icon-md text-warning mx-4"></i>
              </OverlayTrigger>
            }
          </>
        )
      }
    },
  ]

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
          <div className="card card-custom card-stretch">
            {loadingPartner === true
              ?
              <div className="d-flex justify-content-center h-100 items-center">
                <Spinner animation="border" />
              </div>
              :
              <div className="card-body pt-10">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                    <div className="symbol-label" style={{ backgroundImage: 'url("")' }} />
                  </div>
                  <div>
                    <span className="font-weight-bolder font-size-h5 text-dark-75">{partner.name}</span>
                    <div className="text-muted">Contador</div>
                    <div className="text-muted">CNPJ: {maskCnpj(partner.cnpj)}</div>
                  </div>
                </div>

                <div className="pt-4">
                  <SubscriptionStatus subscription={partner.subscription} />
                  {partner.subscription?.status === 'confirmed' ? `  Valido até ${formatToBR(partner.subscription?.expirationDate)}` : ''}
                </div>

                <div className="pt-4 pb-9">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Email:</span>
                    <span className="text-muted text-hover-primary">{partner.email}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Phone:</span>
                    <span className="text-muted">{partner.phone}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="font-weight-bold mr-2">Localização:</span>
                    <span className="text-muted">{partner.address?.city} - {partner.address?.state}</span>
                  </div>
                </div>

                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <button className={`w-100 text-left navi-link py-4  ${tab === 'profile' ? ' active' : ''}`} onClick={() => setTab('profile')}>
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title="Cadastrar"
                            src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Perfil</span>
                    </button>
                  </div>

                  <div className="navi-item mb-2">
                    <button className={`w-100 text-left navi-link py-4  ${tab === 'customers' ? ' active' : ''}`} onClick={() => setTab('customers')}>
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title="Cadastrar"
                            src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Clientes</span>
                    </button>
                  </div>

                  <div className="navi-item mb-2">
                    <button className={`w-100 text-left navi-link py-4  ${tab === 'subscription' ? ' active' : ''}`} onClick={() => setTab('subscription')}>
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title="Cadastrar"
                            src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Assinatura</span>
                    </button>
                  </div>

                  <div className="navi-item mb-2">
                    <button className={`w-100 text-left navi-link py-4  ${tab === 'changePassword' ? ' active' : ''}`} onClick={() => setTab('changePassword')}>
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title="Cadastrar"
                            src={toAbsoluteUrl("/media/svg/icons/General/Shield-protected.svg")}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Alterar senha</span>
                      <span className="navi-label">
                        <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>}
          </div>
        </div>

        <div className="flex-row-fluid ml-lg-8">
          {partner && onboarding.id && (!onboarding.saleMade || new Date() < new Date(onboarding.saleMade)) && <div className="bg-gray-50 mb-6 rounded">
            <OnboardingResume
              onboarding={onboarding}
              partnerCustomerQuantity={partner.customerQuantity}
              customersCount={customers.length}
            />
          </div>}

          {tab === 'profile' &&
            <form className="card card-custom">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">Informações de perfil</h3>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">Dados cadastrais do contador</span>
                </div>
                <div className="card-toolbar">
                  {/* <button type="submit" className="btn btn-success mr-2">Salvar Alterações</button>
                <a className="btn btn-secondary" href="/metronic/react/demo1/user-profile/profile-overview">Cancel</a> */}
                </div>
              </div>
              <div className="form">
                {loadingPartner === true
                  ?
                  <div className="d-flex justify-content-center p-8 items-center">
                    <Spinner animation="border" />
                  </div>
                  :
                  <div className="card-body">

                    <div className="row">
                      <label className="col-xl-3" />
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6 text-xl">Informações do contador</h5>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Nome</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="First name"
                          className="form-control form-control-lg form-control-solid"
                          name="firstname"
                          defaultValue={partner.name}
                        /></div>
                    </div>

                    <div className="row">
                      <label className="col-xl-3" />
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mt-10 mb-6 text-xl">Informações de contato</h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Phone</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-phone" />
                            </span>
                          </div>
                          <input
                            readOnly
                            type="text"
                            placeholder="(43)1122-1111"
                            className="form-control form-control-lg form-control-solid "
                            name="phone" defaultValue={partner.phone}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Whatsapp</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-phone" />
                            </span>
                          </div>
                          <input
                            readOnly
                            type="text"
                            placeholder="(43)1122-1111"
                            className="form-control form-control-lg form-control-solid "
                            name="phone" defaultValue={partner.whatsapp}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Email</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-at" />
                            </span>
                          </div>
                          <input
                            readOnly
                            type="email"
                            placeholder="Email"
                            className="form-control form-control-lg form-control-solid "
                            name="email"
                            defaultValue={partner.email}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-xl-3" />
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mt-10 mb-6 text-xl">Informações de Endereço</h5>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Cep</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="CEP"
                          className="form-control form-control-lg form-control-solid"
                          name="cep"
                          defaultValue={partner.address?.zipcode}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Rua</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="Rua"
                          className="form-control form-control-lg form-control-solid"
                          name="street"
                          defaultValue={partner.address?.street}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Número</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="Número"
                          className="form-control form-control-lg form-control-solid"
                          name="number"
                          defaultValue={partner.address?.number}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Bairro</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="Bairro"
                          className="form-control form-control-lg form-control-solid"
                          name="neighborhood"
                          defaultValue={partner.address?.neighborhood}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Estado</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="Estado"
                          className="form-control form-control-lg form-control-solid"
                          name="state"
                          defaultValue={partner.address?.state}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Cidade</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          readOnly
                          type="text"
                          placeholder="Cidade"
                          className="form-control form-control-lg form-control-solid"
                          name="city"
                          defaultValue={partner.address?.city}
                        />
                      </div>
                    </div>
                  </div>}
              </div>
            </form>
          }

          {tab === 'customers' &&
            <div className="card card-custom">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">Clientes</h3>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">Base de clientes do contador</span>
                </div>
                <div className="card-toolbar">
                  {/* <button type="submit" className="btn btn-success mr-2">Salvar Alterações</button>
                <a className="btn btn-secondary" href="/metronic/react/demo1/user-profile/profile-overview">Cancel</a> */}
                </div>
              </div>
              <div className="p-8">
                {loadingCustomer === true
                  ?
                  <div className="d-flex justify-content-center p-8 items-center">
                    <Spinner animation="border" />
                  </div>
                  :
                  <BootstrapTable
                    classes="table table-head-custom table-vertical-center table-head-bg table-borderless"
                    bordered={false}
                    keyField='id'
                    data={customers}
                    columns={customerColumns}
                    pagination={paginationFactory({ hideSizePerPage: true })}
                  />}
              </div>
            </div>
          }

          {tab === 'subscription' &&
            <div className="card card-custom">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">Assinatura</h3>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">Assinatura do serviço e forma de pagamento</span>
                </div>
                <div className="card-toolbar">
                  {/* <button type="submit" className="btn btn-success mr-2">Salvar Alterações</button>
                <a className="btn btn-secondary" href="/metronic/react/demo1/user-profile/profile-overview">Cancel</a> */}
                </div>
              </div>
              <div className="p-8">
                {loadingCustomer === true
                  ?
                  <div className="d-flex justify-content-center p-8 items-center">
                    <Spinner animation="border" />
                  </div>
                  :
                  <div>

                    <div className="row">
                      <label className="col-xl-3" />
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6 text-xl">Plano</h5>
                      </div>
                    </div>

                    {partner.subscription ?
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label">Nome</label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              readOnly
                              type="text"
                              placeholder="First name"
                              className="form-control form-control-lg form-control-solid"
                              name="firstname"
                              defaultValue="Plano semestral com desconto"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label">Início</label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              readOnly
                              type="text"
                              placeholder="First name"
                              className="form-control form-control-lg form-control-solid"
                              name="firstname"
                              defaultValue={formatToBR(partner.subscription.createdAt)}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label">Próxima cobrança</label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              readOnly
                              type="text"
                              placeholder="First name"
                              className="form-control form-control-lg form-control-solid"
                              name="firstname"
                              defaultValue={formatToBR(partner.subscription.expirationDate)}
                            />
                          </div>
                        </div>
                      </>
                      :
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Nome</label>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            readOnly
                            type="text"
                            placeholder="MM/YYYY"
                            className="form-control form-control-lg form-control-solid"
                            name="firstname"
                            defaultValue={`ainda não assinou nenhum plano`}
                          />
                        </div>
                      </div>
                    }

                    <div className="row">
                      <label className="col-xl-3" />
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6 text-xl mt-10">Forma de pagamento</h5>
                      </div>
                    </div>

                    {partner.subscription ?
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label">Método</label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              readOnly
                              type="text"
                              placeholder="First name"
                              className="form-control form-control-lg form-control-solid"
                              name="firstname"
                              defaultValue={partner.subscription.creditCard ? 'Cartão de Crédito' : 'Boleto'}
                            />
                          </div>
                        </div>

                        {partner.subscription.creditCard &&
                          <>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">Número do cartão</label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  readOnly
                                  type="text"
                                  placeholder="**** **** **** ****"
                                  className="form-control form-control-lg form-control-solid"
                                  name="firstname"
                                  defaultValue={`**** **** **** ${partner.subscription.creditCard.last4CardNumber}`}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">Vencimento</label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  readOnly
                                  type="text"
                                  placeholder="MM/YYYY"
                                  className="form-control form-control-lg form-control-solid"
                                  name="firstname"
                                  defaultValue={`${partner.subscription.creditCard.expirationMonth}/${partner.subscription.creditCard.expirationYear}`}
                                />
                              </div>
                            </div>
                          </>
                        }
                      </>
                      :
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Método</label>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            readOnly
                            type="text"
                            placeholder="MM/YYYY"
                            className="form-control form-control-lg form-control-solid"
                            name="firstname"
                            defaultValue={`ainda não cadastrou um meio de pagamento!`}
                          />
                        </div>
                      </div>
                    }

                    <hr className="mt-10" />
                    <div className="row mt-4">
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6 text-xl">Histórico de cobranças</h5>
                      </div>
                    </div>

                    <div className="flex w-full mt-2">
                      {partner.subscription ?
                        <BootstrapTable
                          classes="table table-head-custom table-vertical-center table-head-bg table-borderless"
                          bordered={false}
                          keyField='id'
                          data={[{
                            id: partner.subscription.id,
                            date: partner.subscription.createdAt,
                            amount: 'R$ 299,40',
                            status: partner.subscription.status,
                            charge: partner.subscription.charge,
                            isCreditCard: Boolean(partner.subscription.creditCard?.creditCardId)
                          }]}
                          columns={[
                            {
                              dataField: 'amount',
                              text: 'Total',
                              sort: true,
                            },
                            {
                              dataField: 'date',
                              text: 'Data',
                              sort: true,
                              formatter: (cell, row) => (<span>{formatToBR(row.createdAt)}</span>),
                            },
                            {
                              dataField: 'status',
                              text: 'Status',
                              sort: true,
                              formatter: (cell, row) => (
                                <div className="">
                                  {row.status === 'confirmed' ?
                                    <Badge variant="success">pago</Badge>
                                    :
                                    <Badge variant="primary">pendente</Badge>
                                  }
                                </div>
                              ),
                            },
                            {
                              dataField: 'id',
                              text: '',
                              sort: false,
                              formatter: (cell, row) => (
                                <div className="">
                                  {!row.charge?.checkoutUrl && (
                                    <>
                                      <button className="btn btn-light-danger btn-xs d-flex px-1 py-0">
                                        link indisponível
                                      </button>
                                    </>
                                  )}

                                  {row.charge && row.status === 'pending' ?
                                    <CopyToClipboard text={row.charge.checkoutUrl}
                                      // onCopy={() => updateCopied()}
                                      >
                                      <button className="btn btn-light btn-xs d-flex p-1" >
                                        <span>Copiar </span>
                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                          <SVG
                                            title="Copiar"
                                            src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                                          ></SVG>
                                        </span>
                                      </button>
                                    </CopyToClipboard>
                                    :
                                    row.charge &&
                                    <a href={row.charge.checkoutUrl} rel="noopener noreferrer" target="_blank" >ver</a>
                                  }
                                </div>
                              ),
                            },
                          ]}
                        />
                        :
                        <div className="flex justify-center w-full h-auto">
                          Não histórico!
                        </div>
                      }
                    </div>
                  </div>}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
