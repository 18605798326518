import React from "react";
// import { GrowthStats } from "./components/GrowthStats";
import {NewArrivals} from './components/NewArrivals'
import { SalesProgress } from "./components/SalesProgress";

export function DashboardPage() {
  return (
    <>
      <div className="row">
        {/*<div className="col-lg-4">*/}
        {/*  <GrowthStats />*/}
        {/*</div>*/}
        <div className="col-lg-8">
          <NewArrivals />
        </div>
        <div className="col-lg-4">
          <SalesProgress />
        </div>
      </div>
    </>
  )
}
