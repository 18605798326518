import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {Sales} from "./pages/sales/";
import { Accountants } from "./pages/accountants/";
import {DashboardPage} from "./pages/dashboard/DashboardPage";
import { EditAccountant } from "./pages/accountants/details";
import { Index } from "./pages/institution";
import {ProductTypeIndex} from "./pages/productType/ProductTypeIndex";

export default function BasePage() {
     return (
      <Suspense fallback={<LayoutSplashScreen />}>
       <Switch>
         {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard"/>
         }
         <ContentRoute path="/dashboard" component={DashboardPage} />
         <ContentRoute path="/sales" component={Sales} />
         <ContentRoute path="/accountants/:id" component={EditAccountant} />
         <ContentRoute path="/accountants" component={Accountants} />
         <ContentRoute path="/institution" component={Index} />
         <ContentRoute path="/product/type" component={ProductTypeIndex} />
         <Redirect to="error/error-v1" />
       </Switch>
     </Suspense>
  );
}
