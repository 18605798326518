import React, {useEffect, useState} from "react";
import { Spinner, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import http from "../../../core/services/http";

export const CreateProductsForm = ({ onCreated, idInstitution, productTypeList }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [requestError, setRequestError] = useState({ show: false });
  const [fields, setFields] = useState();
  const [banner, setBanner] = useState({ value: '', type: 'video', name: '' });

  useEffect(() => {
    makeFields(productTypeList[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeFields = (productTypeId) => {
    const productType = productTypeList.filter(elem => elem.id === productTypeId);
    setFields(productType[0].fields.map(elem => ({ name: elem.name, label: elem.label, type: elem.type, unit: elem.type === 'number' ? 'R$' : '', value: '' })));
  }

  const submitAccountant = (data) => {
    let bannerObj = {
      name: banner.name,
      type: banner.type
    }
    if(banner.type === 'image') {
      const reader = new FileReader();
      reader.readAsDataURL(banner.image[0]);
      reader.onload = () => {
        bannerObj = { ...bannerObj, image: reader.result };
      }
    } else {
      bannerObj = { ...bannerObj, link: banner.value };
    }
    const newData = {
      name: data.name,
      remark: data.remark,
      fields: fields,
      financial_institution_id: data.financial_institution_id,
      product_type_id: data.product_type_id
    }
    setLoadingCreate(true);
    http.post('product', newData)
      .then(async res => {
        await http.post('product-banner', { ...bannerObj, product_id: res.data.data.id });
        onCreated();
      })
      .catch(({ response }) => setRequestError({
        show: true, ...response.data
      }))
      .finally(() => setLoadingCreate(false))
  }

  const fieldErrors = (errors) => {
    let item = []
    for (let error in errors) {
      item.push(errors[error][0])
    }
    return item
  }

  const changeFields = (index, value, field) => {
    let newValueFields = fields.map(elem => elem);
    newValueFields[index][field] = value;
    setFields(newValueFields);
  }

  return (
    <>
      {requestError.show &&
      <Alert variant="danger" dismissible onClose={() => setRequestError({ show: false })}>
        {requestError.message}
        {fieldErrors(requestError.errors).map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </Alert>
      }

      <form onSubmit={handleSubmit(submitAccountant)}>
        <input type="hidden" id="financial_institution_id"
               ref={register({ required: true })}
               name="financial_institution_id" value={idInstitution}/>
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="name">Nome</label>
            <input
              name="name"
              ref={register({ required: true })}
              id="name"
              type="text"
              className="form-control"
            />
            {errors.name && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="remark">Descrição</label>
            <textarea
              name="remark"
              ref={register({ required: true })}
              id="remark"
              className="form-control"
            />
            {errors.remark && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="product_type_id">Tipo</label>
            <select name="product_type_id" id="product_type_id"
                    onChange={(e) => makeFields(e.target.value)}
                    className="form-control" ref={register({ required: true })}>
              {productTypeList.map(elem => (<option value={elem.id}>{elem.name}</option>))}
            </select>
            {errors.type && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        {fields ? fields.map((elem, index) => (
          <div className="form-group row">
            <div className="col-md-8">
              <label htmlFor={elem.name}>{elem.label}</label>
              <input
                name={elem.name}
                ref={register({ required: true })}
                id={elem.name}
                value={elem.value}
                onChange={(e) => changeFields(index, e.target.value, 'value')}
                type="text"
                className="form-control"
              />
              {errors[elem.name] && <span className="text-danger">Este campo é obrigatório</span>}

            </div>
            {elem.type === "number" ? (
              <div className="col-md-4 d-flex items-end">
                <select value={elem.unit}
                        onChange={(e) => changeFields(index, e.target.value, 'unit')}
                        className="form-control">
                  <option value="R$">R$</option>
                  <option value="dias">Dias</option>
                  <option value="meses">Meses</option>
                  <option value="%">%</option>
                </select>
              </div>
            ) : ""}
          </div>
        )) : ""}

        <div>
          <p className="font-bold mb-3">
            Banner
          </p>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="banner_name">Nome</label>
            <input
              name="banner_name"
              ref={register({ required: true })}
              id="banner_name"
              value={banner.name}
              onChange={(e) => setBanner({ ...banner, name: e.target.value })}
              type="text"
              className="form-control"
            />
            {errors.banner_name && <span className="text-danger">Este campo é obrigatório</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-4 d-flex items-end">
            <select onChange={(e) => setBanner({ ...banner, value: '', type: e.target.value })}
                    className="form-control"
                    id="type_banner">
              <option value="video">Youtube</option>
              <option value="image">Imagem</option>
            </select>
          </div>
          <div className="col-md-8">
            <label>Link</label>
            <input
              id="banner_value"
              name="banner_value"
              value={banner.value}
              onChange={e => {
                if(banner.type === 'image') {
                  setBanner({ ...banner, image: e.target.files, value: e.target.value });
                  return;
                }
                setBanner({ ...banner, value: e.target.value });
              }}
              ref={register({ required: true })}
              type={banner.type === 'image' ? 'file' : 'text'}
              className="form-control"
            />
          </div>
          {errors.banner_value && <span className="text-danger" style={{ marginLeft: "180px" }}>Este campo é obrigatório</span>}
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loadingCreate}
            >
              Salvar
              {loadingCreate && <Spinner animation="border" size="sm" className="ml-2" />}
            </button>
          </div>
        </div>

      </form>
    </>
  )
}
