import React, { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import http from "../../../core/services/http";

export function SaleForm({ data, onChanged }) {

  const { register, handleSubmit } = useForm()
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [requestError, setRequestError] = useState({ show: false })

  const handleSaveChanges = (form) => {
    setLoadingCreate(true)
    http.put(`partner/deal-opportunities/${data.id}`, form)
      .then(() => onChanged())
      .catch(({ response }) => setRequestError({
        show: true, ...response.data
      }))
      .finally(() => setLoadingCreate(false))
  }

  return (
    <>
      {requestError.show &&
        <Alert variant="danger" dismissible onClose={() => setRequestError({ show: false })}>
          {requestError.message}
        </Alert>
      }

      <form onSubmit={handleSubmit(handleSaveChanges)} className="">
        <div className="row">

          <div className="col-lg-12">

            <h4>Cliente: </h4>
            <div>
              <strong className="d-inline-flex">Cliente:</strong>
              <span className="d-inline-flex ml-2">{data.customer?.name}</span>
            </div>

            <div>
              <strong className="d-inline-flex">CPF/CNPJ:</strong>
              <span className="d-inline-flex ml-2">{data.customer?.cpf ?? data.customer?.cnpj}</span>
            </div>

            <div>
              <strong className="d-inline-flex">Email: </strong>
              <span className="d-inline-flex ml-2">{data.customer?.email}</span>
            </div>

            <div>
              <strong className="d-inline-flex">Telefone: </strong>
              <span className="d-inline-flex ml-2">{data.customer?.phone}</span>
            </div>

            <div>
              <strong className="d-inline-flex">Nascimento/Inicio atividades: </strong>
              <span className="d-inline-flex ml-2">{data.customer?.birthday}</span>
            </div>
          </div>

          <div className="col-md-12">
            <h4 className="mt-10">Contador: </h4>
            <span>{data.partner?.name}</span>
          </div>

          <div className="col-md-12">
            <h4 className="mt-10">Motivo: </h4>
            <span>{data.reason}</span>
          </div>

          <div className="col-md-12">
            <h4 className="mt-10">Solicitação: </h4>
            <span>{data.desired_amount}</span>
          </div>

          <div className="form-group col-md-12">
            <label className="h4 mt-10" htmlFor="approved_value">Status</label>
            <select
              name="status"
              ref={register}
              defaultValue={data.status}
              id="status" className="form-control"
            >
              <option value="pending">Pendente</option>
              <option value="processing">Processando</option>
              <option value="rejected">Indeferido</option>
              <option value="concluded">Deferido</option>
              <option value="canceled">Cancelado</option>
            </select>
          </div>

          <div className="form-group col-md-12">
            <label className="h4" htmlFor="approved_value">Aprovação</label>
            <input
              name="approved_amount"
              ref={register}
              defaultValue={data.approved_amount}
              id="approved_value"
              type="text"
              className="form-control"
            />
          </div>

          <div className="form-group col-md-12">
            <label className="h4" htmlFor="approved_value">Observações</label>
            <textarea
              name="remark"
              ref={register}
              defaultValue={data.remark}
              id="remark"
              className="form-control"
            />
          </div>

        </div>


        <div className="form-group row">
          <div className="col-md-12">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loadingCreate}>
              Salvar
              {loadingCreate && <Spinner animation="border" size="sm" className="ml-2" />}
            </button>
          </div>
        </div>


      </form>
    </>
  )
}
